import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <iframe title="Presentation" className='press'  src="https://online.fliphtml5.com/pbfyk/omam/"  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" ></iframe>
      </header>
    </div>
  );
}

export default App;
